import styled from 'styled-components';
import useNaverCrawl from '../hooks/useNaverCrawl';

const Container = styled.div`
  
`;

const ButtonSection = styled.div`
  
`;

export default function NaverKinConsult({
  index, url, setUrl, addUrl, deleteUrl,
}: {
  index: number;
  url: string;
  setUrl: (index: number, url: string) => void;
  addUrl: () => void;
  deleteUrl: (index: number) => void;
}) {
  const {
    copyNokeLink,
    copyFinalAdvice,
    crawlOutput,
  } = useNaverCrawl();

  return (
    <Container>
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(index, e.target.value)}
        placeholder="네이버 지식인 URL을 입력하세요"
      />
      <button type="button" onClick={addUrl}>+</button>
      <button type="button" onClick={() => deleteUrl(index)}>-</button>
      {
      crawlOutput.length !== 0
        ? (
          <ButtonSection>
            <button type="button" onClick={() => copyFinalAdvice(index)}>생성된 조언 복사</button>
            <button type="button" onClick={() => copyNokeLink(index)}>생성된 link 복사</button>
          </ButtonSection>
        )
        : null
      }
    </Container>
  );
}
