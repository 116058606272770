import { encode } from 'urlencode';
import Inquiry from '../type/Inquiry';
import ConsultationCategory from '../type/ConsultationCategory';
import consultationCategoryDictionary from '../data/consultationCategoryDictionary';

function formatAdvice(advice: string) {
  const paragraphs = advice.split('\n\n\n');

  const first = paragraphs[0];
  const second = paragraphs[1] || '';
  const third = paragraphs[2] || '';
  const truncatedAdvice = [first, second, third].filter(Boolean).join('\n\n');

  return truncatedAdvice;
}

export default function createOutput(
  inquiries: Inquiry[],
  advices: string[],
  category: ConsultationCategory,
) {
  const outputs = inquiries.map((inquiry, index) => {
    const formattedAdvice = formatAdvice(advices[index]);

    const kinLink = inquiry.url;

    const query = `utm_source=naver&utm_medium=organic&utm_campaign=지식인&naverInquiry=${encode(inquiry.toString())}`;
    const urlByCategory = `https://noke.ai/consultations/${category}`;
    const nokeLink = `${urlByCategory}?${query}`;

    const { tags } = consultationCategoryDictionary[category];

    const serviceDescription = '▼▼▼추가로 궁금증이나 질문 있으시면 아래 카드를 클릭해 문의바랍니다.▼▼▼';

    const finalAdvice = [formattedAdvice, serviceDescription, tags].join('\n\n');

    return {
      kinLink, nokeLink, finalAdvice,
    };
  });

  return outputs;
}
