import styled from 'styled-components';
import useNaverCrawl from '../hooks/useNaverCrawl';
import PageLoading from '../components/PageLoading';
import NaverKinConsult from '../components/NaverKinConsult';
import useNaverKinCrawlInput from '../hooks/useNaverKinCrawlInput';
import consultationCategoryDictionary from '../data/consultationCategoryDictionary';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 50px 100px;

  input {
    width: 700px;
  }
`;

const NaverCookie = styled.div`
  display: flex;
  gap: 100px;

  > button {
    width: 200px;
    height: 50px;
  }
`;

const NaverKinCrawlSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

const Head = styled.div`
  display: flex;
  gap: 20px;
`;

export default function MainPage() {
  const {
    setUrlList,
    setUrl,
    addUrl,
    deleteUrl,
    setNaverCookie,
    urlList,
    naverCookie,
    category,
    categories,
    setCategory,
  } = useNaverKinCrawlInput();

  const {
    isLoading,
    consultAllUrl,
    initializeCrawlOutput,
  } = useNaverCrawl();

  const handleClickConsult = () => {
    if (!category) {
      alert('답변달고자하는 분야를 선택해주세요!');
      return;
    }

    if (naverCookie.trimStart() === '') {
      alert('오늘자 네이버 쿠키를 복붙해주세요!');
      return;
    }

    consultAllUrl(urlList, naverCookie.trimStart(), category);
  };

  const handleClickInitialize = () => {
    setUrlList(['']);
    initializeCrawlOutput();
  };

  return (
    isLoading
      ? <PageLoading />
      : (
        <Container>
          <Head>
            <h2>네이버 지식인 작업 페이지</h2>
            <div className="dropdown">
              <button className="btn btn-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {category ? consultationCategoryDictionary[category].korean : '답변 분야 선택'}
              </button>
              <ul className="dropdown-menu">
                {categories.map((c) => (
                  <li key={c}>
                    <button className="dropdown-item" type="button" onClick={() => setCategory(c)}>
                      {consultationCategoryDictionary[c].korean}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Head>
          <NaverCookie>
            <input
              type="text"
              value={naverCookie}
              onChange={(e) => setNaverCookie(e.target.value)}
              placeholder="네이버 쿠키를 입력하세요"
            />
            <button type="button" onClick={handleClickConsult}>지식인 답변 생성하기</button>
            <button type="button" onClick={handleClickInitialize}>초기화</button>
          </NaverCookie>
          <NaverKinCrawlSection>
            {urlList.map((url, index) => (
              <NaverKinConsult
                index={index}
                url={url}
                setUrl={setUrl}
                addUrl={addUrl}
                deleteUrl={deleteUrl}
              />
            ))}
          </NaverKinCrawlSection>
        </Container>
      )
  );
}
