import ConsultationCategory from '../type/ConsultationCategory';

const consultationCategoryDictionary: {
  [Key in ConsultationCategory]: {
    korean: string;
    serviceDescription: string;
    tags: string;
  }
} = {
  legal: {
    korean: '법률',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 변호사 선임이나 사건을 맡기실 필요 없습니다. 법률사무소가 아니며 광고도 아닙니다.

2. 100% 무료로 법률상담을 제공해드립니다.

3. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

4. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료법률상담 AI변호사 AI법률상담 민사법률상담 형사법률상담 형량예측 법령정보 판례찾기 법률상담',
  },
  medical: {
    korean: '의료',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 의료 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료의료상담 AI의사 AI의료상담 내과상담 의학정보 증상분석 건강관리 정신건강 정형외과 성형외과',
  },
  admission: {
    korean: '입시',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 입시상담을 제공해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료입시상담 AI입시컨설턴트 AI입시상담 대학입시상담 생기부관리 세특주제 대입전략 합격가능성분석 전형문의 성적향상',
  },
  insurance: {
    korean: '보험',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 보험 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료보험상담 AI보험설계사 AI보험상담 보험상품비교 맞춤형보험추천 보험리모델링 보험가입전략 보험료분석 보장내용문의 보험료절약방법',
  },
  veterinary: {
    korean: '동물의료',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 반려동물 관련 의료 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료동물의료상담 AI수의사 AI동물의료상담 고양이 길고양이 고양이건강 강아지 강아지건강 동물병원 수의사',
  },
  travel: {
    korean: '여행',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 원하시는 나라 및 일정에 맞게 여행 플랜을 세워드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료여행가이드 AI여행가이드 여행가이드 국내여행 해외여행 일본여행 미국여행 중국여행 베트남여행 유럽여행',
  },
  investment: {
    korean: '투자',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 투자 전략, 종목 분석 등 투자 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료투자분석 AI투자전문가 AI투자분석 미국주식 해외주식 국내주식 비트코인 양도소득세 업비트 키움증권',
  },
  automobile: {
    korean: '자동차',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 자동차 추천, 비교 등 자동차 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료자동차추천 AI자동차추천 중고차 자동차수리 현대자동차 기아자동차 자동차리스 차 신차구입 자동차구입',
  },
  relationship: {
    korean: '연애',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 연애 관련 질문에 답변해드립니다.

2. 비밀보장으로 상담을 진행합니다.

3. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

4. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료연애상담 연애고민 AI연애상담 남자친구 남친 이별 전남친 연애고민상담 썸남 여친',
  },
  medicine: {
    korean: '약',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 약 효능/부작용, 영양제 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: 'AI약사 고혈압 발기부전 감기약 생리 스테로이드 피부약 약 영양제 영양제추천',
  },
  computer: {
    korean: '컴퓨터',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 컴퓨터 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료컴퓨터상담 포토샵 엑셀 컴퓨터사양 컴퓨터 노트북 컴퓨터조립 노트북추천 게이밍노트북 컴퓨터추천',
  },
  tutoring: {
    korean: '과외',
    serviceDescription: `추가질문이나 자세한 풀이를 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 과목별 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료과외 문제풀이 국어 사회 과학 영어 제2외국어 무료문제풀이 과외선생님 메가스터디',
  },
  subsidy: {
    korean: '지원금',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 지원금/지원정책 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료지원금상담 AI지원금상담 국민취업지원 국비지원 청년 신혼부부 국민취업지원제도 국비지원 정부지원 정부정책',
  },
  health: {
    korean: '헬스',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 헬스, 웨이트트레이닝 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료운동상담 영양제 다이어트 근성장 등운동 가슴운동 어깨운동 하체운동 헬스 PT',
  },
  shopping: {
    korean: '쇼핑',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 상황에 맞는 제품을 추천해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료제품추천 AI제품추천 가전제품 컴퓨터 가구 스포츠용품 옷 패딩 레저용품 러닝화',
  },
  career: {
    korean: '커리어',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 커리어 및 직업, 진로 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료직업상담 AI직업상담사 AI커리어상담 공무원 이직 취업 면접 직업 자격증 퇴직',
  },
  marketing: {
    korean: '마케팅',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 마케팅 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료마케팅컨설팅 AI마케터 마케팅 광고 메타공고 영업 네이버광고 검색광고 퍼포먼스마케팅 SNS마케팅',
  },
  cosmetics: {
    korean: '화장품',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 질문자님의 피부에 맞는 화장품을 추천 및 비교해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료화장품추천 AI화장품디렉터 피부 올리브영 쿠션추천 화장품추천 스킨케어 메이크업 화장품성분 수분크림',
  },
  real_estate: {
    korean: '부동산',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 부동산 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료부동산상담 AI공인중개사 무료공인중개사 월세 전세대출 청약 부동산계약 보증금 임대차계약 아파트',
  },
  restaurant: {
    korean: '맛집',
    serviceDescription: `추가적인 맛집 추천이나 식당 평가를 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 상황에 맞는 맛집을 추천해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료맛집추천 AI맛집추천 카페 맛집 맛집추천 서울맛집 강남구맛집 마포구맛집 줄서는집 용산구맛집',
  },
  loan: {
    korean: '대출',
    serviceDescription: `추가질문이나 자세한 상담을 원하시면 **노크 상담 서비스**를 이용해주세요.

1. 100% 무료로 대출 관련 질문에 답변해드립니다.

2. 궁금하신 것은 24시간 언제든지 질문하실 수 있습니다.

3. 질문하시면 3 ~ 10초 안에 답변이 옵니다.

아래 링크로 접속하시면 상담이 시작됩니다.`,
    tags: '무료대출상담 AI대출상담 디딤돌대출 대출문의 전세대출 주택담보대출 비상금대출 햇살론 신용대출 신혼부부대출',
  },
};

export default consultationCategoryDictionary;
